import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import PageHeader from "../../components/PageHeader";
import Head from "../../components/Head";
import WideSection from "../../components/WideSection";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <PageHeader title="Angel Investing" mb={0} mdxType="PageHeader">
  <Head title="Angel Investing – Max Stoiber (@mxstbr)" description="I angel invest in early-stage startups I'm excited about. Learn more about how I decide and which investments I've already made!" image="https://cdn.splitbee.io/og/57fadbbdf5?headline=Angel%20investing" mdxType="Head" />
    </PageHeader>
    <p>{`Together with `}<a parentName="p" {...{
        "href": "https://sueodio.com"
      }}>{`Sue Odio`}</a>{`, we angel invest small ($2.5k - $5k) checks in early-stage startups we're excited about. Think of us as advisors with skin in the game — we love helping with tricky, ambiguous problems, providing access to our networks and audiences, or even just lending an ear.`}</p>
    <blockquote>
      <p parentName="blockquote">{`Max is one of our highest value-add investors: he constantly gives us good product feedback and knows everything about open source.`}</p>
      <p parentName="blockquote">{`On top of that, every time he talks about us to his developer audience our stats go wild.`}</p>
      <p parentName="blockquote">{`– Brendan Falk, CEO Fig`}</p>
    </blockquote>
    <p>{`If you're founding a startup and think this could be a good fit we'd love to meet you. `}<a href="mailto:contact@mxstbr.com?cc=suemko@gmail.com">{`Send us an email`}</a>{` and let's chat!`}</p>
    <h3>{`What we invest in`}</h3>
    <p>{`We invest first-and-foremost in the people. Our main filter is "Do we believe these founders can go through the one-of-a-kind challenge of building a company that can change the world?"`}</p>
    <p>{`We love investing early to make the most of the limited amount of money we have. The earlier, the better.`}</p>
    <p>{`I personally love investing in products that I (would) use myself, even more so if they're products aimed at developers.`}</p>
    <h3>{`Investments`}</h3>
    <p>{`So far, I have invested in:`}</p>
    <ol>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://fly.io"
        }}>{`Fly.io`}</a>{`, a platform for running global apps`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://incident.io"
        }}>{`Incident.io`}</a>{`, a platform for incident management and response`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://raycast.com"
        }}>{`Raycast`}</a>{`, an extensible Spotlight for developers`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://getdx.com/"
        }}>{`DX`}</a>{`, a developer experience platform`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://clerk.dev"
        }}>{`Clerk`}</a>{`, a frontend-first user management solution`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://liveblocks.io"
        }}>{`Liveblocks`}</a>{`, a toolchain to build real-time collaborative products`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://upstash.com/"
        }}>{`Upstash`}</a>{`, a database for the serverless world`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://zed.dev"
        }}>{`Zed`}</a>{`, a text editor for the 21st century`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://arcjet.com/"
        }}>{`Arcjet`}</a>{`, a developer-first approach to security`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://campsite.design"
        }}>{`Campsite`}</a>{`, a home for your design team's work-in-progress`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://stackshare.io"
        }}>{`StackShare`}</a>{`, a system of record for tech stacks`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.remotefirstcapital.com/"
        }}>{`Remote First Capital`}</a>{`, a fund by Andreas Klinger`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://magicbell.io"
        }}>{`Magic Bell`}</a>{`, an embeddable notification system`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://devjobs.at"
        }}>{`DevJobs`}</a>{`, a job matching platform for developers`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://www.outsmartly.com/"
        }}>{`Outsmartly`}</a>{`, a CDN for performantly personalizing websites`}</li>
      <li parentName="ol"><a parentName="li" {...{
          "href": "https://storytell.ai"
        }}>{`Storytell.ai`}</a>{`, an AI tool to extract signal from noise`}</li>
      <li parentName="ol"><em parentName="li"><a parentName="em" {...{
            "href": "https://fig.io"
          }}>{`Fig`}</a>{` (`}<a parentName="em" {...{
            "href": "https://fig.io/blog/post/fig-joins-aws"
          }}>{`acq. by AWS`}</a>{`), a terminal for the 21st century`}</em></li>
      <li parentName="ol"><em parentName="li"><a parentName="em" {...{
            "href": "https://museapp.com"
          }}>{`Muse`}</a>{` (`}<a parentName="em" {...{
            "href": "https://museapp.com/end-and-beginning/"
          }}>{`acq. by bootstrapper`}</a>{`), a tool for thought for deep work`}</em></li>
      <li parentName="ol"><em parentName="li"><a parentName="em" {...{
            "href": "https://ab.bot"
          }}>{`Abbot`}</a>{` (`}<a parentName="em" {...{
            "href": "https://ab.bot"
          }}>{`sunset`}</a>{`, a copilot for customer success in Slack`}</em></li>
      <li parentName="ol"><em parentName="li"><a parentName="em" {...{
            "href": "https://along.video"
          }}>{`Along`}</a>{` (`}<a parentName="em" {...{
            "href": "https://twitter.com/alongvideo/status/1610361111513288704"
          }}>{`sunset`}</a>{`), an online video platform focussed on creators`}</em></li>
      <li parentName="ol"><em parentName="li"><a parentName="em" {...{
            "href": "https://rome.tools"
          }}>{`Rome`}</a>{` (`}<a parentName="em" {...{
            "href": "https://biomejs.dev/blog/annoucing-biome/"
          }}>{`sunset`}</a>{`), a modern JavaScript toolchain`}</em></li>
    </ol>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      